import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

export const query = graphql`
  query SeminarPageQuery($identifier: String!) {
    speakers(identifier: { eq: $identifier }) {
      name
      contentHtml
      picture {
        childImageSharp {
          gatsbyImageData(
            width: 400
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER, cropFocus: ATTENTION }
          )
        }
      }
    }
  }
`;

export interface SeminarPageProps {
  data: {
    speakers: {
      name: string;
      contentHtml: string;
      picture: any;
    };
  };
}

export default class SpeakerPage extends React.Component<SeminarPageProps> {
  get backUrl(): string {
    // read the previous page from the url and return it
    const url = new URL(window.location.href);
    const previousPage = url.searchParams.get('previousPage');
    const decoded = decodeURIComponent(previousPage || '');
    return decoded || '/';
  }

  render() {
    const speaker = this.props.data.speakers;
    const picture = getImage(speaker?.picture);
    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-16 px-6 py-16 max-w-screen-lg">
          <div className="flex flex-row gap-12">
            {/* {picture && (
              <GatsbyImage
                image={picture}
                alt={speaker.name}
                objectFit="cover"
                imgClassName="w-48 h-72 rounded-xl"
              />
            )} */}
            <div className="flex flex-col gap-10">
              <div className="flex flex-col gap-2">
                <h1 className="text-6xl font-black">{speaker.name}</h1>
              </div>
              <div
                className="year-content"
                dangerouslySetInnerHTML={{ __html: speaker.contentHtml }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
